import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { getAppConfig } from "../../../Utils/AppConfig";
import dayjs from "dayjs";
import fileDownload from "js-file-download";

type ServerError = { errorMessage: string };

const findAccessLog = async (payload: String) => {
    // await keycloak.authorizationHeaders()

    // Todo : Call request by Axios
    try {
        const result = await axios.post(`${getAppConfig().MIDDLEWARE_URI}/api/accessLog/findAccessLog`, payload, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        })
        return result?.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            if (serverError && serverError.response) {
                return serverError.response.data;
            }
        }
        return { errorMessage: error };
    }
}

const createAccessLog = async (payload: String) => {
    // await keycloak.authorizationHeaders()

    // Todo : Call request by Axios
    try {
        const result = await axios.post(`${getAppConfig().MIDDLEWARE_URI}/api/accessLog/createAccessLog`, payload, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        })
        return result?.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            if (serverError && serverError.response) {
                return serverError.response.data;
            }
        }
        return { errorMessage: error };
    }
}

// const createAccessLogAgentBroker = async (payload : String) => {
//     // await keycloak.authorizationHeaders()

//     // Todo : Call request by Axios
//     try {
//             // const result = await axios.post(`http://localhost:8080/api/v1/api/accessLog/createAccessLogAgentBroker`, payload, {

//         const result = await axios.post(`${getAppConfig().MIDDLEWARE_URI}/api/accessLog/createAccessLogAgentBroker`,payload, {
//             headers: {
//                 "authorization": "Bearer " + localStorage.getItem("access_token"),
//                 'Content-Type': 'application/json',
//                 "Access-Control-Allow-Origin": "*"
//             }
//         })
//         return result?.data
//     } catch (error) {
//         if (axios.isAxiosError(error)) {
//             const serverError = error as AxiosError<ServerError>;
//             if (serverError && serverError.response) {
//                 return serverError.response.data;
//             }
//         }
//         return { errorMessage: error };
//     }
// }
const createAccessLogAgentBroker = async (payload: String) => {
    // console.log("url createAccessLogAgentBroker" + getAppConfig().MIDDLEWARE_URI)
    // const result = await axios.post(`http://localhost:8080/api/v1/api/accessLog/createAccessLogAgentBroker`, payload, {
    // const result = await axios.post(`${getAppConfig().MIDDLEWARE_URI}/api/accessLog/createAccessLogAgentBroker`,payload, {
    const result = await axios.post(`https://etemp-gateway-uat.rabbitlife.co.th/v1/middleware/api/accessLog/createAccessLogAgentBroker`, payload, {

        headers: {
            "authorization": "Bearer " + localStorage.getItem("access_token"),
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*"
        }

    }).then(result => {
        return result.data
    }).catch(error => {
        console.log(error);
    });

    return result;

};

const outAccessLog = async (id: String) => {
    // await keycloak.authorizationHeaders()

    // Todo : Call request by Axios
    try {
        // console.log('url : ')
        // console.log(`${getAppConfig().MIDDLEWARE_URI}`)
        // console.log(localStorage.getItem("access_token"))
        const result = await axios.get(`${getAppConfig().MIDDLEWARE_URI}/api/accessLog/outAccessLog?id=${id}`, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        })
        // console.log('result?.data : ',result?.data)
        return result?.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            if (serverError && serverError.response) {
                return serverError.response.data;
            }
        }
        return { errorMessage: error };
    }
}

const exportfileCsv = async (body: String) => {

    const result = await axios.post(`${getAppConfig().MIDDLEWARE_URI}/api/accessLog/exportCSV`, body, {
        headers: {
            'Content-Type': 'application/json',
            "authorization": "Bearer " + localStorage.getItem("access_token"),
            "Access-Control-Allow-Origin": "*"
        },
        responseType: 'arraybuffer'

    }).then(result => {
        let date = dayjs().format('BBBB/MM/DD')
        // console.log("result.data : ", result.data)
        fileDownload(result.data, `${date.replaceAll('/', '')}_DATA.csv`)
        return result.data
    }).catch(error => {
        console.log(error);
        return []
    });

    return result;
};



export default {
    findAccessLog,
    createAccessLog,
    createAccessLogAgentBroker,
    outAccessLog,
    exportfileCsv
}

