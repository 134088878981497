import { Dayjs } from 'dayjs';
import React from 'react'
import { createContext, useState } from "react";


interface ReceiptReportContextValues {

    agentCode: string;
    applicantMiddlenName: string;
    applicantName: string;
    applicantSurname: string;
    totalPages: number;
    firstPage: boolean;
    lastPage: boolean;
    firstLoad: boolean;
    page: number;
    currentPage: number;
    totalElements: number;
    tempReceiptDateFrom: Dayjs | null;
    tempReceiptDateTo: Dayjs | null;
    paymentDateFrom: Dayjs | null;
    paymentDateTo: Dayjs | null;
    dataDocTempporary: never[];
    setAgentCode: (agentCode: string) => void;
    setApplicantMiddlenName: (applicantMiddlenName: string) => void;
    setApplicantName: (applicantName: string) => void;
    setApplicantSurname: (applicantSurname: string) => void;
    setTotalPages: (totalPages: number) => void;
    setFirstPage: (firstPage: boolean) => void;
    setLastPage: (lastPage: boolean) => void;
    setFirstLoad: (firstLoad: boolean) => void;
    setPage: (page: number) => void;
    setCurrentPage: (currentPage: number) => void;
    setTotalElements: (totalElements: number) => void;
    setTempReceiptDateFrom: (tempReceiptDateFrom: Dayjs | null) => void;
    setTempReceiptDateTo: (tempReceiptDateTo: Dayjs | null) => void;
    setPaymentDateFrom: (paymentDateFrom: Dayjs | null) => void;
    setPaymentDateTo: (paymentDateTo: Dayjs | null) => void;
    setDataDocTempporary: (dataDocTempporary: []) => void;

    clearReceiptContext: () => void;
}

const defaultReceiptReportContextValues: ReceiptReportContextValues = {
    agentCode: "",
    applicantMiddlenName: "",
    applicantName: "",
    applicantSurname: "",
    totalPages: 0,
    firstPage: false,
    lastPage: false,
    firstLoad: true,
    page: 0,
    currentPage: 0,
    totalElements: 0,
    tempReceiptDateFrom: null,
    tempReceiptDateTo: null,
    paymentDateFrom: null,
    paymentDateTo: null,
    dataDocTempporary: [],
    setAgentCode: () => { },
    setApplicantMiddlenName: () => { },
    setApplicantName: () => { },
    setApplicantSurname: () => { },
    setTotalPages: () => { },
    setFirstPage: () => { },
    setLastPage: () => { },
    setFirstLoad: () => { },
    setPage: () => { },
    setCurrentPage: () => { },
    setTotalElements: () => { },
    setTempReceiptDateFrom: () => { },
    setTempReceiptDateTo: () => { },
    setPaymentDateFrom: () => { },
    setPaymentDateTo: () => { },
    setDataDocTempporary: () => { },



    clearReceiptContext: () => { },


};

export const ReceiptReportContext = createContext<ReceiptReportContextValues>(
    defaultReceiptReportContextValues
);

interface AuthContextProviderProps {

    children: JSX.Element;
}

const ReceiptReportContextProvider = (props: AuthContextProviderProps) => {
    const [agentCode, setAgentCode] = useState('')
    const [applicantName, setApplicantName] = useState('')
    const [applicantSurname, setApplicantSurname] = useState('')
    const [applicantMiddlenName, setApplicantMiddlenName] = useState('')
    const [dataDocTempporary, setDataDocTempporary] = useState([]);
    const [totalPages, setTotalPages] = useState(0)
    const [firstPage, setFirstPage] = useState(false)
    const [lastPage, setLastPage] = useState(false)
    const [firstLoad, setFirstLoad] = useState<boolean>(true)
    const [page, setPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [totalElements, setTotalElements] = useState(0)
    const [tempReceiptDateFrom, setTempReceiptDateFrom] = React.useState<Dayjs | null>(
        null,
    );
    const [tempReceiptDateTo, setTempReceiptDateTo] = React.useState<Dayjs | null>(
        null,
    );
    const [paymentDateFrom, setPaymentDateFrom] = React.useState<Dayjs | null>(
        null,
    );
    const [paymentDateTo, setPaymentDateTo] = React.useState<Dayjs | null>(
        null,
    );

    const clearReceiptContext = () => {
        setAgentCode("")
        setApplicantName("")
        setApplicantSurname("")
        setApplicantMiddlenName("")
        setTotalPages(0)
        setFirstPage(false)
        setLastPage(false)
        setFirstLoad(true)
        setPage(0)
        setCurrentPage(0)
        setTotalElements(0)
        setTempReceiptDateFrom(null)
        setTempReceiptDateTo(null)
        setPaymentDateFrom(null)
        setPaymentDateTo(null)
        setDataDocTempporary([])
    }

    return (
        <ReceiptReportContext.Provider
            value={{
                agentCode, applicantMiddlenName, applicantName, applicantSurname, tempReceiptDateFrom, tempReceiptDateTo, paymentDateFrom, paymentDateTo, totalPages, firstPage, lastPage, firstLoad, page,currentPage, totalElements,
                setAgentCode, setApplicantMiddlenName, setApplicantName, setApplicantSurname, setTempReceiptDateFrom, setTempReceiptDateTo, setPaymentDateFrom, setPaymentDateTo,
                clearReceiptContext, dataDocTempporary, setDataDocTempporary, setTotalPages,
                setFirstPage,
                setLastPage,
                setPage,
                setFirstLoad,
                setCurrentPage,
                setTotalElements,
            }}
        >
            {props.children}
        </ReceiptReportContext.Provider>
    )
}

export default ReceiptReportContextProvider