import axios from 'axios'
import { getAppConfig, loadConfig } from "../../../Utils/AppConfig";


const findMenuActiveByRoleAndParent = async () => {
    // await keycloak.authorizationHeaders()
    await loadConfig().then(() => { });
    let url: string = getAppConfig()?.MIDDLEWARE_URI

    // Todo : Call request by Axios
    const result = await axios.get(`${url}/api/admUser/authorizeMenu`, {
        headers: {
            "authorization": "Bearer " + localStorage.getItem("access_token"),
            "Access-Control-Allow-Origin": "*"
        }
    }).then(result => {
        return result.data
    }).catch(error => {
        console.log(error);
        return error.response
    });

    return result;
};

export default {
    findMenuActiveByRoleAndParent
}