import React, { useEffect, useState, useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import "./login-style.css";
// import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, Row } from 'reactstrap';

import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { getAppConfig } from '../../Utils/AppConfig';
import jwt_decode from "jwt-decode";
import IconRabbit from "../../Asserts/images/logo/rabbit_life_tr.png"
import { AuthContext } from '../../Utils/AuthContextProvider';

interface IPassword {
  username: string;
  password: string;
  showPassword: boolean;
}

const Login = () => {
  const { signin, isAuth } = useContext(AuthContext);

  const params = useParams()
  const [values, setValues] = useState<IPassword>({
    username: '',
    password: '',
    showPassword: false,
  });
  const [password, setPassword] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const [incorrect, setIncorrect] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [showSpinner, setShowSpinner] = useState("none");
  const [showButton, setShowButton] = useState("block");
  const [version, setVersion] = useState("1.3.0");

  const handleLogin = () => {
    setShowButton("none");
    setShowSpinner("block");

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");


    let urlencoded = new URLSearchParams();
    urlencoded.append("username", values.username);
    urlencoded.append("password", values.password);
    urlencoded.append("client_id", `${getAppConfig().CLIENT_ID}`);
    urlencoded.append("grant_type", "password");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow" as RequestRedirect
    };


    fetch(`${getAppConfig().AUTHORITY_URI}/protocol/openid-connect/token`, requestOptions)
      .then(response => response.text())
      .then((result: string) => {
        let resultParse: any = JSON.parse(result)
        localStorage.setItem("access_token", resultParse.access_token);
        localStorage.setItem("refresh_token", resultParse.refresh_token);
        localStorage.setItem("session_state", resultParse.session_state);

        let content: any = jwt_decode(resultParse.access_token);
        localStorage.setItem("username", content.preferred_username);
        localStorage.setItem("name", content.name);

        setAuthenticated(true);
        setIncorrect(false);
        setShowButton("block");
        setShowSpinner("none");
      }
      ).catch((error: any) => {
        setAuthenticated(false);
        setIncorrect(true);
        setShowButton("block");
        setShowSpinner("none");
        console.error(error);
      });
  };

  const handleChange =
    (prop: keyof IPassword) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const [url, setUrl] = useState('')
  useEffect(() => {
    let config = getAppConfig()
    let login_uri = `${config?.KEYCLOAK_URI}/realms/${config?.REALMS_ID}/protocol/openid-connect/auth?redirect_uri=${config?.APP_URI}&client_id=${config?.CLIENT_ID}&response_type=code&scope=openid`
    setUrl(login_uri)
  }, [])

  const login = () => {
    window.location.href = url
  }
  if (isAuth) {
    return <Navigate to='/' />
  } else {
    return (
      <>
        <div className="login-container loginImage">
          <Box
            sx={{
              width: 350,
              height: 400,
              backgroundColor: "transparent",
              position: "absolute",
              top: "50%",
              left: "25%",
              transform: "translate(0%, -50%)",
              alignItems: "center",
              justifyContent: "center"

            }}
          >
            <CardMedia
              component="img"
              sx={{ width: "100%", height: 125 }}
              image={IconRabbit}
              alt="logo"
            />
            <Stack
              spacing={5}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%"
              }}>
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  width: "90%",
                }}>
                <Button variant="contained" color="primary" onClick={signin}>sign in</Button>
              </Stack>
            </Stack>

          </Box>
          <div className='version'>Version {version}</div>
        </div>

      </>
    );
  }
}

export default Login;
