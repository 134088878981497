const APP = {
  VERSION: "",
  CONFIG: {
    MIDDLEWARE_URI: "",
    REALMS_ID: "",
    CLIENT_ID: "",
    KEYCLOAK_URI: "",
    APP_URI: "",
    AUTHORITY_URI: ""
  },
};

const fetchFile = (url: string) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const statusCode = xhr.status === 0 ? 200 : xhr.status;
      try {
        const response = JSON.parse(xhr.responseText);
        if (statusCode >= 400) {
          reject(new Error(`Could not fetch file from ${url}`));
        } else {
          resolve(response);
        }
      } catch (err) {
        reject(new Error(`Could not fetch file from ${url}`));
      }
    };
    xhr.onerror = () => {
      reject(new TypeError('Local request failed'));
    };
    xhr.open('GET', url);
    xhr.send(null);
  });

export const getAppConfig = () => ({ ...APP.CONFIG });

export const getAppVersion = () => APP.VERSION;

export const setAppConfig = (configMap: any) => {
  APP.CONFIG = configMap || {};
  APP.VERSION = process.env.REACT_APP_VERSION || '99.0.0';
  console.info("APP.CONFIG", APP);
  console.info(APP);
  console.info("------");
};

export const loadConfig = async () =>
  new Promise(async (resolve) => {
    const basePath = process.env.BASE_PATH || '';
    const configJsonPath = `${basePath}/endpoint.json`;
    // console.log("configJsonPath: ", resolve)
    await fetchFile(configJsonPath).then(setAppConfig).then(resolve);
    // console.log(`fetchFile success`);
  });