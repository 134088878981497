import axios, { AxiosError } from "axios";
import { getAppConfig, loadConfig } from "../../../Utils/AppConfig";
import { log } from "console";

type ServerError = { errorMessage: string };

const getParameterById = async (code: string, page: number) => {
    // await keycloak.authorizationHeaders()
    await loadConfig().then(() => { });
    let url: string = getAppConfig()?.MIDDLEWARE_URI
    // Todo : Call request by Axios
    try {
        const result = await axios.get(`${url}/api/admParameter/findByCode?code=${code}&page=${page}&size=10`, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("access_token"),
                "Access-Control-Allow-Origin": "*"
            }
        })
        // console.log("getParameterById api: ", result?.data.data)
        return result?.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            if (serverError && serverError.response) {
                return serverError.response.data;
            }
        }
        return { errorMessage: error };
    }
}

const createParameterDetail = async (payload: any) => {
    // await keycloak.authorizationHeaders()

    // Todo : Call request by Axios
    try {
        const result = await axios.post(`${getAppConfig().MIDDLEWARE_URI}/api/admParameter/createParameterDetail`, payload, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("access_token"),
                "Access-Control-Allow-Origin": "*"
            }
        })
        // console.log("createParameterDetail api: ", result?.data)
        return result?.data
    } catch (error) {
        // console.log("createParameterDetail: ",error)
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            // console.log("serverError: ",serverError)
            if (serverError && serverError.response) {
                return serverError.response.data;
            }
        }
        return { errorMessage: error };
    }
}

const updateParameterDetail = async (payload: any, pmdId: number) => {
    // await keycloak.authorizationHeaders()

    // Todo : Call request by Axios
    try {
        const result = await axios.post(`${getAppConfig().MIDDLEWARE_URI}/api/admParameter/updateParameterDetail?pmdId=${pmdId}`, payload, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("access_token"),
                "Access-Control-Allow-Origin": "*"
            }
        })
        // console.log("updateParameterDetail api: ", result?.data)
        return result?.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            if (serverError && serverError.response) {
                return serverError.response.data;
            }
        }
        return { errorMessage: error };
    }
}

const deleteParameterDetail = async (pmdId: number) => {
    // await keycloak.authorizationHeaders()

    // Todo : Call request by Axios
    try {
        const result = await axios.delete(`${getAppConfig().MIDDLEWARE_URI}/api/admParameter/removeParameterDetail?pmdId=${pmdId}`, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("access_token"),
                "Access-Control-Allow-Origin": "*"
            }
        })
        // console.log("deleteParameterDetail api: ", result?.data)
        return result?.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            if (serverError && serverError.response) {
                return serverError.response.data;
            }
        }
        return { errorMessage: error };
    }
}

const getPayee = async (payload: String) => {
    // await keycloak.authorizationHeaders()
    // console.log(payload)
    await loadConfig().then(() => { });
    let url: string = getAppConfig()?.MIDDLEWARE_URI
    // Todo : Call request by Axios
    try {
        const result = await axios.post(`${url}/api/admParameter/findAdmPayee`, payload, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        })
        // console.log("getParameterById api: ", result?.data.data)
        return result?.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            if (serverError && serverError.response) {
                return serverError.response.data;
            }
        }
        return { errorMessage: error };
    }
}

const getStaff = async (payload: String) => {
    // await keycloak.authorizationHeaders()
    // console.log(payload)
    await loadConfig().then(() => { });
    let url: string = getAppConfig()?.MIDDLEWARE_URI
    // Todo : Call request by Axios
    try {
        const result = await axios.post(`${url}/api/admParameter/findAdmUsers`, payload, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        })
        // console.log("getParameterById api: ", result?.data.data)
        return result?.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            if (serverError && serverError.response) {
                return serverError.response.data;
            }
        }
        return { errorMessage: error };
    }
}

const findAdmUserByLogin = async (location: string, role: string) => {
    // await keycloak.authorizationHeaders()
    await loadConfig().then(() => { });
    let url: string = getAppConfig()?.MIDDLEWARE_URI
    // Todo : Call request by Axios
    try {
        const result = await axios.get(`${url}/api/admParameter/findAdmUserByLogin?location=${location}&role=${role}`, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        })
        // console.log("getParameterById api: ", result?.data.data)
        return result?.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            if (serverError && serverError.response) {
                return serverError.response.data;
            }
        }
        return { errorMessage: error };
    }
}

const createPayee = async (payload: any) => {
    // await keycloak.authorizationHeaders()

    // Todo : Call request by Axios
    try {
        const result = await axios.post(`${getAppConfig().MIDDLEWARE_URI}/api/admParameter/createAdmPayee`, payload, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("access_token"),
                "Access-Control-Allow-Origin": "*"
            }
        })
        // console.log("createParameterDetail api: ", result?.data)
        return result?.data
    } catch (error) {
        // console.log("createParameterDetail: ",error)
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            // console.log("serverError: ",serverError)
            if (serverError && serverError.response) {
                return serverError.response.data;
            }
        }
        return { errorMessage: error };
    }
}

const saveMiniuteExpire = async (payload: any) => {
    // await keycloak.authorizationHeaders()

    // Todo : Call request by Axios
    try {
        const result = await axios.post(`${getAppConfig().MIDDLEWARE_URI}/api/admParameter/saveMiniuteExpire`, payload, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("access_token"),
                "Access-Control-Allow-Origin": "*"
            }
        })
        // console.log("createParameterDetail api: ", result?.data)
        return result?.data
    } catch (error) {
        // console.log("createParameterDetail: ",error)
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            // console.log("serverError: ",serverError)
            if (serverError && serverError.response) {
                return serverError.response.data;
            }
        }
        return { errorMessage: error };
    }
}

const getMiniuteExpire = async (payload: any) => {
    // await keycloak.authorizationHeaders()

    // Todo : Call request by Axios
    try {
        const result = await axios.post(`${getAppConfig().MIDDLEWARE_URI}/api/admParameter/getMiniuteExpire`, payload, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("access_token"),
                "Access-Control-Allow-Origin": "*"
            }
        })
        // console.log("createParameterDetail api: ", result?.data)
        return result?.data
    } catch (error) {
        // console.log("createParameterDetail: ",error)
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            // console.log("serverError: ",serverError)
            if (serverError && serverError.response) {
                return serverError.response.data;
            }
        }
        return { errorMessage: error };
    }
}

const createStaff = async (payload: any) => {
    // await keycloak.authorizationHeaders()

    // Todo : Call request by Axios
    try {
        const result = await axios.post(`${getAppConfig().MIDDLEWARE_URI}/api/admParameter/createAdmUser`, payload, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("access_token"),
                "Access-Control-Allow-Origin": "*"
            }
        })
        // console.log("createParameterDetail api: ", result?.data)
        return result?.data
    } catch (error) {
        // console.log("createParameterDetail: ",error)
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            // console.log("serverError: ",serverError)
            if (serverError && serverError.response) {
                return serverError.response.data;
            }
        }
        return { errorMessage: error };
    }
}

const updatePayee = async (payload: any) => {
    // await keycloak.authorizationHeaders()

    // Todo : Call request by Axios
    try {
        const result = await axios.post(`${getAppConfig().MIDDLEWARE_URI}/api/admParameter/updateAdmPayee`, payload, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        })
        // console.log("updateParameterDetail api: ", result?.data)
        return result?.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            if (serverError && serverError.response) {
                return serverError.response.data;
            }
        }
        return { errorMessage: error };
    }
}

const updateUser = async (payload: any) => {
    // await keycloak.authorizationHeaders()

    // Todo : Call request by Axios
    try {
        const result = await axios.post(`${getAppConfig().MIDDLEWARE_URI}/api/admParameter/updateAdmUser`, payload, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("access_token"),
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        })
        // console.log("updateParameterDetail api: ", result?.data)
        return result?.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            if (serverError && serverError.response) {
                return serverError.response.data;
            }
        }
        return { errorMessage: error };
    }
}

const deletePayee = async (payeeId: any) => {
    // await keycloak.authorizationHeaders()

    // Todo : Call request by Axios
    try {
        const result = await axios.delete(`${getAppConfig().MIDDLEWARE_URI}/api/admParameter/deleteAdmPayee?payeeId=${payeeId}`, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("access_token"),
                "Access-Control-Allow-Origin": "*"
            }
        })
        // console.log("createParameterDetail api: ", result?.data)
        return result?.data
    } catch (error) {
        // console.log("createParameterDetail: ",error)
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            // console.log("serverError: ",serverError)
            if (serverError && serverError.response) {
                return serverError.response.data;
            }
        }
        return { errorMessage: error };
    }
}

const deleteStaff = async (staffId: number, relationId: Number) => {
    // await keycloak.authorizationHeaders()

    // Todo : Call request by Axios
    try {
        const result = await axios.delete(`${getAppConfig().MIDDLEWARE_URI}/api/admParameter/deleteAdmUser?staffId=${staffId}&relationId=${relationId}`, {
            headers: {
                "authorization": "Bearer " + localStorage.getItem("access_token"),
                "Access-Control-Allow-Origin": "*"
            }
        })
        // console.log("createParameterDetail api: ", result?.data)
        return result?.data
    } catch (error) {
        // console.log("createParameterDetail: ",error)
        if (axios.isAxiosError(error)) {
            const serverError = error as AxiosError<ServerError>;
            // console.log("serverError: ",serverError)
            if (serverError && serverError.response) {
                return serverError.response.data;
            }
        }
        return { errorMessage: error };
    }
}

export default {
    getParameterById,
    createParameterDetail,
    updateParameterDetail,
    deleteParameterDetail,
    getPayee,
    getStaff,
    findAdmUserByLogin,
    createPayee,
    createStaff,
    updateUser,
    updatePayee,
    deletePayee,
    deleteStaff,
    saveMiniuteExpire,
    getMiniuteExpire
}