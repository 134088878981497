import React from 'react'
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type THeader = {
    title: string
    subtitle: string
    children?: React.ReactNode
}
function Header({ title, subtitle, children }: THeader) {
    return (
        <>
            <Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" pb={1}>
                    <Stack>
                        <Typography color={"header.main"} variant="h5">{title}</Typography>
                        <Typography color={"header.contrastText"} variant="subtitle1">{subtitle}</Typography>
                    </Stack>
                    {children}
                </Stack>
                <hr className="mt-0" />
            </Stack>
        </>
    )
}

export default Header