import React, { useContext } from "react";
import { AuthContext } from "./Utils/AuthContextProvider";
import LoadingSpinner from "./Components/LoadingSpinner";
import Home from "./Pages/Home";
import { BrowserRouter as Router, Routes, Route, Outlet, Link } from "react-router-dom";
import Login from "./Pages/Login";
import './App.css';
import { ThemeProvider, createTheme, inputLabelClasses } from "@mui/material";
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider, } from 'react-query'


// const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

const theme = createTheme({
  status: {
    danger: '#FF8000'
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ff5e43',
      // dark: will be calculated from palette.primary.main,
      contrastText: '#ffffff',
    },
    warning: {
      main: '#FFBC26',
      contrastText: '#000',
    },
    header: {
      light: '#ffa726',
      main: '#ff5e43',
      dark: '#ef6c00',
      contrastText: '#999999',
    }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            color: '#ff5e43',
            "&.Mui-disabled": {
              color: '#ff5e43',
            },
          },
          label: {
            color: '#1F9BFD',
            shrink: {
              color: '#1F9BFD',
            },
            borderBottom: '#1F9BFD',
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        input: {
          color: '#ff5e43',
          "&.Mui-disabled": {
            color: '#ff5e43 !important',
            "WebkitTextFillColor": "#ff5e43 !important",
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: '#1F9BFD',
          },
          '&:after': {
            borderBottomColor: '#1F9BFD',
          },
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#1F9BFD',
          "&.Mui-disabled": {
            color: '#1F9BFD',
          },
        },
        shrink: {
          color: '#1F9BFD',
        },
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: '#1F9BFD'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: '#ff5e43',
            color: "#ffffff",
            "& .MuiListItemIcon-root": {
              color: "#ffffff",
            },
            "&:hover": {
              backgroundColor: '#b2412e',
              color: "#ffffff",
              "& .MuiListItemIcon-root": {
                color: "#ffffff",
              }
            }
          },
          "&:hover": {
            backgroundColor: '#ff5e43',
            color: "#ffffff",
            "& .MuiListItemIcon-root": {
              color: "#ffffff",
            }
          }
        },
      }
    }
  }

})

// Create a client
const queryClient = new QueryClient()

const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}>
          <React.Suspense fallback={<LoadingSpinner />}>
            <Router>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="login/:token" element={<Login />} />
                <Route path="/*" element={<Home />} />
              </Routes>
            </Router>
          </React.Suspense>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
