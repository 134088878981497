import Keycloak, { KeycloakConfig, KeycloakInitOptions } from "keycloak-js";
import { createContext, useEffect, useState, useContext } from "react";
import { getAppConfig } from "./AppConfig";
import AccessLogApi from "../Services/api/AccessLog/AccessLogApi";

const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: "login-required",
};

const keycloak = new Keycloak("/keycloak.json");

interface AuthContextValues {

  isAuthenticated: boolean;

  username: string;

  logout: () => void;

  hasRole: (role: string) => boolean;

  isSignin: boolean;

  signin: any,

  isAuth: boolean;
}

const defaultAuthContextValues: AuthContextValues = {
  isAuthenticated: false,
  username: "",
  logout: () => { },
  hasRole: (role) => false,
  isSignin: false,
  signin: () => { },
  isAuth: false
};

export const AuthContext = createContext<AuthContextValues>(
  defaultAuthContextValues
);

interface AuthContextProviderProps {

  children: JSX.Element;
}

const AuthContextProvider = (props: AuthContextProviderProps) => {
  // console.log("rendering AuthContextProvider");

  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [isSignin, setIsSignin] = useState<boolean>(false);
  const [isAuth, setAuth] = useState<boolean>(false);

  useEffect(() => {
    const signinValue = localStorage.getItem('isSignin')
    if (!!signinValue && signinValue === "true") {
      setIsSignin(true)
    }
    async function initializeKeycloak() {
      // console.log("initialize Keycloak");
      if (isSignin) {
        try {
          const isAuthenticatedResponse = await keycloak.init(
            keycloakInitOptions
          );
          if (!isAuthenticatedResponse) {
            // console.log(
            //   "user is not yet authenticated. forwarding user to login."
            // );
            keycloak.login();
          }

          // console.log("user already authenticated");
          setAuthenticated(isAuthenticatedResponse);
        } catch {
          console.error("error initializing Keycloak");
          console.error(`realm: ${keycloak.realm}`);
          console.error(`clientId: ${keycloak.clientId}`);
          console.error(`url: ${keycloak.authServerUrl}`);
          setAuthenticated(false);
        } finally {
          setIsSignin(false)
          localStorage.setItem('isSignin', "false")
        }
      }
    }

    initializeKeycloak();
  }, [isSignin]);

  useEffect(() => {
    async function loadProfile() {
      try {
        const profile = await keycloak.loadUserProfile();
        if (profile.firstName) {
          setUsername(profile.firstName);
        } else if (profile.username) {
          setUsername(profile.username);
        }
      } catch {
        console.log("error trying to load the users profile");
      }
    }

    async function createAccess(data: any) {
      try {
        const id = await AccessLogApi.createAccessLog(JSON.stringify(data))
        localStorage.setItem("accessLogId", id.toString())
      } catch {
        console.log("error trying to createAccess");
      }
    }

    if (isAuthenticated) {
      loadProfile();
      if (keycloak.token && keycloak.tokenParsed) {
        localStorage.setItem("access_token", keycloak?.token)
        let data = {
          "email": keycloak.tokenParsed?.email,
          "first_name": keycloak?.tokenParsed?.given_name,
          "last_name": keycloak?.tokenParsed?.family_name,
          "agent_unit": null,
          "agent_unit_code": null,
          "agent_code": null,
          "agent_type": null,
          "iat": keycloak?.tokenParsed?.iat,
          "exp": keycloak?.tokenParsed?.exp,
          "iss": keycloak?.tokenParsed?.iss,
        }
        const dataLog = {
          "email": keycloak.tokenParsed?.email,
          "first_name": keycloak?.tokenParsed?.given_name,
          "last_name": keycloak?.tokenParsed?.family_name,
          "agent_unit": null,
          "agent_unit_code": null,
          "agent_code": null,
          "agent_type": null,
          "iat": keycloak?.tokenParsed?.iat,
          "exp": keycloak?.tokenParsed?.exp,
          "iss": keycloak?.tokenParsed?.iss,
        }
        createAccess(dataLog)


        localStorage.setItem("userProfile", JSON.stringify(data))
        // console.log("keycloak?.tokenParsed: ", keycloak?.tokenParsed)
        setAuth(true)
      }
      // console.log("Keycloak: token", keycloak.token, keycloak.tokenParsed)
    }
  }, [isAuthenticated]);

  const logout = async () => {
    const id = localStorage.getItem("accessLogId")
    const resOut = await AccessLogApi.outAccessLog(id!!)
    // console.log('resOut : ',resOut);

    setTimeout(() => {
      window.location.href = `${keycloak.authServerUrl}/realms/${keycloak.realm}/protocol/openid-connect/logout?post_logout_redirect_uri=${getAppConfig().APP_URI}&client_id=${keycloak.clientId}`
    }, 1000)
    localStorage.clear()
  };

  const hasRole = (role: string) => {
    return keycloak.hasRealmRole(role);
  };

  const signin = () => {
    localStorage.setItem('isSignin', "true")
    setIsSignin(true)
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, username, logout, hasRole, isSignin, signin, isAuth }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
