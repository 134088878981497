import React, { useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { MenuItem as MuiMenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import CardMedia from '@mui/material/CardMedia';
import LogoutIcon from '@mui/icons-material/Logout';
import EngineeringIcon from '@mui/icons-material/Engineering';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import DescriptionIcon from '@mui/icons-material/Description';
import PersonIcon from '@mui/icons-material/Person';

import { AuthContext } from "../../Utils/AuthContextProvider";
import jwt_decode from "jwt-decode";
import SideBar, { TSideBarObj } from '../../Components/SideBar/SideBar';
import { hasChildren } from "../../Utils/HasChildren";
import IconRabbit from "../../Asserts/images/logo/rabbit_life.png"


//API
import MenuAPI from "../../Services/api/Menu/MenuAPI"
import Configuration from '../Configuration';
import { useQuery } from 'react-query'
import AccessLogApi from "./../../Services/api/AccessLog/AccessLogApi";

//page
const UserMaintenance = React.lazy(() => import('../Administrator/UserMaintenance'));
const RoleMaintenance = React.lazy(() => import('../Administrator/RoleMaintenance'));
const TemporaryReceiptMaintenance = React.lazy(() => import('../TemporaryReceiptMaintenance'));

const TemporaryReceiptNext = React.lazy(() => import('../TemporaryReceiptNext'));
const CreateTemporaryReceiptNextCreated = React.lazy(() => import('../TemporaryReceiptNext/Create'));
const CreateTemporaryReceiptNextDetail = React.lazy(() => import('../TemporaryReceiptNext/Detail'));

const ReportTemporaryReceipt = React.lazy(() => import('../ReportTemporaryReceipt'));
const ReceiptMaintenanceDetail = React.lazy(() => import('../ReceiptMaintenanceDetail'));
const CreateReceiptMaintenance = React.lazy(() => import('../CreateReceiptMaintenance'));
const AccessLog = React.lazy(() => import('../AccessLog'));
const Payee = React.lazy(() => import('../Payee'));
const Staff = React.lazy(() => import('../Staff'));
const Expire = React.lazy(() => import('../Expire'));

interface Props {
  window?: () => Window;
}
const objMenu: any = {
  icons: {
    "TR000": <DescriptionIcon />,
    "MS000": <DisplaySettingsIcon />,
    "AD000": <EngineeringIcon />
  },
  url: {
    "TR000": ["/temporaryReceipt/search", "/createReceiptMaintenance", "/temporaryReceipt/renewal", "/temporaryReceipt/report", "/temporaryReceipt/create", "/temporaryReceipt/renewal/Create", "/temporaryReceipt/renewal/Detail", "/receiptMaintenanceDetail"],
    "MS000": ["/admin/config/MS001", "/admin/config/MS002", "/admin/config/MS003", "/admin/config/MS004", "/admin/config/MS005", "/admin/config/MS006", "/admin/config/MS007", "/admin/config/payee", "/admin/config/expire"],
    "AD000": ["/admin/roleMaintenance", "/admin/staff", "/admin/accessLog"]
  }
}

const Home = (props: Props) => {
  // Access the client
  const { data, error, isError, isLoading } = useQuery('MENUS', MenuAPI.findMenuActiveByRoleAndParent)

  const { logout } = useContext(AuthContext);
  const { window } = props;

  let location = useLocation();
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const agentToken = searchParams.get('token');

  const [menus, setMenus] = useState<TSideBarObj>([]);
  const [sessionExpire, setSessionExpire] = useState(false);
  const [displayMenu, setDisplayMenu] = useState("none");
  const [displayLoader, setDisplayLoader] = useState("block");
  useEffect(() => {
    if (agentToken) {
      try {
        let decoded: any = jwt_decode(agentToken);
        let data = JSON.stringify(decoded)

        localStorage.clear()
        localStorage.setItem("userProfile", data);
        localStorage.setItem("access_token", agentToken);
      } catch (error) {
        console.error(error)
        localStorage.clear();
        return navigate('/login')
      }
    }
    fetchData()
  }, [data])

  async function createAccess(data: any) {
    try {
      const id = await AccessLogApi.createAccessLog(JSON.stringify(data))
      localStorage.setItem("accessLogId", id.toString())
    } catch {
      console.log("error trying to createAccess");
    }
  }

  async function createAccessAgentBroker(data: any) {
    try {
      const id = await AccessLogApi.createAccessLogAgentBroker(JSON.stringify(data))
      localStorage.setItem("accessLogId", id.toString())
    } catch {
      console.log("error trying to createAccess");
    }
  }

  const fetchData = async () => {
    try {
      const res: any = data
      if (res?.status === 401) {
        localStorage.clear();
        return navigate('/login')
      } else {
        if (res?.length) {
          if (location.pathname !== "/" && location.pathname !== "" && location.pathname !== "/createReceiptMaintenance" && location.pathname !== "/receiptMaintenanceDetail" && location.pathname !== undefined) {
            let noAccess = res?.filter((x: any) => x.urlLink === location.pathname)
            if (noAccess.length === 0) {
              navigate('/404')
            }
          }
        }
        const userProfile = localStorage.getItem("userProfile")
        if (!!userProfile) {
          var jsonData = JSON.parse(userProfile)

          const dataLog = {
            "email": jsonData.email,
            "first_name": jsonData.first_name,
            "last_name": jsonData.last_name,
            "agent_unit": jsonData.agent_unit,
            "agent_unit_code": jsonData.agent_unit_code,
            "agent_code": jsonData.agent_code,
            "agent_type": jsonData.agent_type,
            "iat": jsonData.iat,
            "exp": jsonData.exp,
            "iss": jsonData.iss,
          }

          if (dataLog.agent_type === "agent" || dataLog.agent_type === "broker") {
            createAccessAgentBroker(dataLog)
          }
          // }else{
          //   createAccess(dataLog)
          // }
          // 

          let profile = { ...jsonData, role: res?.role }
          if (!!res?.profile) {
            profile = { ...profile, first_name: res?.profile?.firstName, last_name: res?.profile?.lastName }
          }
          localStorage.setItem("userProfile", JSON.stringify(profile))
        }
        localStorage.setItem("permission", JSON.stringify(res.permission!!));
        loadMenuSetting(res?.menu)
      }
    } catch (error) {
      console.error(error)
    }
  }
  const [menuState, setMenuState] = useState<any>({})
  const loadMenuSetting = (tempMenu: any) => {
    let data = [];
    for (let x = 0; x < tempMenu?.length; x++) {
      let headMenu: any = {};
      let subMenu: any = [];
      if (tempMenu[x].menuId === tempMenu[x].parent) {
        headMenu = {
          title: tempMenu[x].menuLang1,
          class: 'head-menu',
          url: tempMenu[x].urlLink,
          icon: objMenu.icons[tempMenu[x]?.code] ? objMenu.icons[tempMenu[x]?.code] : <ListOutlinedIcon />,
          code: tempMenu[x]?.code
        };
        if (tempMenu[x].flagHasChild === 'Y') {
          headMenu.child = []
        }
        setMenuState({ ...menuState, [tempMenu[x]?.code]: false })
        data.push(headMenu);
      }
      for (let y = 1; y < tempMenu?.length; y++) {
        if (tempMenu[x].parent === tempMenu[y].parent && tempMenu[y].parent !== tempMenu[y].menuId) {
          subMenu.push({
            title: tempMenu[y].menuLang1,
            class: 'sub-menu',
            url: tempMenu[y].urlLink,
            icon: <FiberManualRecordOutlinedIcon />,
          })
        }
      }
      if (subMenu.length) {
        headMenu.child = subMenu;
      }
    }
    setMenus(data)
    setSessionExpire(false)
    setDisplayMenu('block')
    setDisplayLoader('none')
  }



  const drawerWidth = 240;

  const MenuItem = ({ item }: { item: any }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} />;
  };

  const SingleLevel = ({ item }: { item: any }) => {
    return (
      <>
        {/* conditions path /temporaryReceipt/search , /temporaryReceipt/renewal , else */}
        {item?.url === "/temporaryReceipt/search" ? (
          <ListItem
            selected={
              location.pathname.includes("/createReceiptMaintenance") ||
              location.pathname.includes("/receiptMaintenanceDetail") ||
              location.pathname.includes("/temporaryReceipt/search")
            }
            button
            onClick={() => { navigate(item?.url); }}
            sx={{ "&.Mui-selected": { bgcolor: "primary.light" } }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        ) : item?.url === "/temporaryReceipt/renewal" ? (
          <ListItem
            selected={
              location.pathname.includes("/temporaryReceipt/renewal") ||
              location.pathname.includes("/temporaryReceipt/renewal/Create") ||
              location.pathname.includes("/temporaryReceipt/renewal/Detail")
            }
            button
            onClick={() => { navigate(item?.url); }}
            sx={{ "&.Mui-selected": { bgcolor: "primary.light" } }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        ) : (
          <ListItem
            selected={
              item?.url?.includes(location.pathname) && location.pathname !== '/'
            }
            button
            onClick={() => { navigate(item?.url); }}
            sx={{ "&.Mui-selected": { bgcolor: "primary.light" } }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        )}
      </>
    );
  };

  const MultiLevel = ({ item }: { item: any }) => {
    const { child: children } = item;
    const handleClick = (key: string) => {
      setMenuState({ ...menuState, [key]: !menuState[key] })
    };

    return (
      <List component="nav" disablePadding>
        <ListItem button selected={objMenu?.url[item?.code]?.includes(location.pathname)} onClick={() => handleClick(item?.code)}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.title} />
          {menuState[item?.code] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={menuState[item?.code]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child: any, key: any) => (
              <MenuItem key={key} item={child} />
            ))}
          </List>
        </Collapse>
      </List>
    );
  };

  // Drawer sidbar
  const container = window !== undefined ? () => window().document.body : undefined;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const renderUser = () => {
    const userProfile = localStorage.getItem("userProfile")
    const username = localStorage.getItem("name")
    if (!!userProfile || !!username) {
      if (!!userProfile) {
        var jsonData = JSON.parse(userProfile)
        return `${jsonData?.first_name} ${jsonData?.last_name}`
      } else {
        return `${username}`
      }
    } else return ''

  }
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: 'primary.main' }}>
        <Container maxWidth={false}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { lg: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <CardMedia
                component="img"
                sx={{ width: "21rem", height: "3rem" }}
                image={IconRabbit}
                alt="logo"
              />
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MuiMenuItem disabled>
                  <Button variant="text" startIcon={<PersonIcon />}>
                    <Typography sx={{
                      // display: 'block',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '150px'
                    }}>
                      {renderUser()}
                    </Typography>
                  </Button>
                </MuiMenuItem>
                <MuiMenuItem onClick={logout}>
                  <Button variant="text" startIcon={<LogoutIcon />} fullWidth>
                    Logout
                  </Button>
                </MuiMenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
        aria-label="menu"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: false,
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <Toolbar />
          {menus.map((item, key) => <MenuItem key={key} item={item} />)}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', lg: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          <Toolbar />
          {menus.map((item, key) => <MenuItem key={key} item={item} />)}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Container maxWidth={false}>
          <Outlet />
          <Routes>
            <Route path="/admin/userMaintenance" element={<UserMaintenance />} />
            <Route path="/admin/roleMaintenance" element={<RoleMaintenance />} />
            <Route path="/temporaryReceipt/search" element={<TemporaryReceiptMaintenance />} />
            <Route path="/temporaryReceipt/renewal" element={<TemporaryReceiptNext />} />
            <Route path="/temporaryReceipt/renewal/Create" element={<CreateTemporaryReceiptNextCreated />} />
            <Route path="/temporaryReceipt/renewal/Detail" element={<CreateTemporaryReceiptNextDetail />} />
            <Route path="/temporaryReceiptMaintenance" element={<TemporaryReceiptMaintenance />} />
            <Route path="/temporaryReceipt/report" element={<ReportTemporaryReceipt />} />
            <Route path="/receiptMaintenanceDetail" element={<ReceiptMaintenanceDetail />} />
            <Route path="/createReceiptMaintenance" element={<CreateReceiptMaintenance />} />
            <Route path="/admin/config/:code" element={<Configuration />} />
            <Route path="/admin/accessLog" element={<AccessLog />} />
            <Route path="/admin/config/payee" element={<Payee />} />
            <Route path="/admin/config/expire" element={<Expire />} />
            <Route path="/admin/staff" element={<Staff />} />
          </Routes>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;

