import React, { useEffect, useState, useRef } from 'react'
import { Card, CardBody, Row, Col, PaginationItem, PaginationLink, Table, Pagination } from "reactstrap";
import { useLocation, useParams } from "react-router-dom"
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Button as MuiButton } from '@mui/material';
import { VariantType, useSnackbar } from 'notistack';

import Header from '../../Components/Header/Header';
import ConfigurationAPI from '../../Services/api/Configuration/ConfigurationAPI';

//page
const ParameterDetail = React.lazy(() => import('./ParameterDetail'));

interface IKeys {
    add: string;
    edit: string;
}

const fontActive = {
    color: 'green',
    fontWeight: 800
};

const fontInActive = {
    color: 'red',
    fontWeight: 800
};

export const TitleData: any = {
    MS001: { title: "Temporary Receipt Running - MS001", subtitle: "Temporary Receipt Running", flagPlanCode: true },
    MS002: { title: "Bank - MS002", subtitle: "กำหนดค่าธนาคาร", flagPlanCode: true },
    MS003: { title: "Mode of payment - MS003", subtitle: "Mode of payment", flagPlanCode: true },
    MS004: { title: "Temporary Receipt Status - MS004", subtitle: "Temporary Receipt Status", flagPlanCode: true },
    MS005: { title: "Cancel Remark - MS005", subtitle: "Cancel Remark", flagPlanCode: true },
    MS006: { title: "Payment Type - MS006", subtitle: "Payment Type", flagPlanCode: true },
    MS007: { title: "Plan Code - MS007", subtitle: "Plan Code", flagPlanCode: false }
};

function Configuration() {
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation()
    const { code } = useParams()
    const codeRef = useRef('');

    // pagination
    const [page, setPage] = useState(0)
    const [totalElements, setTotalElements] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [firstPage, setFirstPage] = useState(false)
    const [lastPage, setLastPage] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [permissionData, setPermissionData] = useState<{ code: number, description: string, flag_active: string, name: boolean, flagPlanCode: string }[]>([])
    const [pmtId, setPmtId] = useState<number>(0)

    const fetchData = async (current: number) => {
        try {
            if (!!code) {
                const resPermissionData: any = await ConfigurationAPI.getParameterById(code, current)
                setPermissionData(resPermissionData?.data?.content)
                setTotalElements(resPermissionData?.data?.totalElements)
                setPage(resPermissionData?.data?.number)
                setTotalPages(resPermissionData?.data?.totalPages)
                setFirstPage(resPermissionData?.data?.first)
                setLastPage(resPermissionData?.data?.last)
                setPmtId(Number(resPermissionData?.pmtId))
            }
        } catch (error) {
            console.warn(error)
        }
    };

    const renderPagination = () => {
        let paging = [];
        let numOfPaginationButton = 1;
        let $pageSize = totalPages > 5 ? 5 : totalPages;
        if (currentPage + 5 < totalPages) {
            for (let x = currentPage; x < totalPages; x++) {
                numOfPaginationButton++;
                paging.push(
                    <PaginationItem role="pageNumberHandle" key={x} onClick={event => {
                        pageNumberHandle(event, x)
                    }} active={x === page}>
                        <PaginationLink className="paggination-custom-style" tag="button">
                            {x + 1}
                        </PaginationLink>
                    </PaginationItem>
                );

                if (numOfPaginationButton > $pageSize)
                    break;
            }
        } else {
            for (let x = totalPages - $pageSize; x < totalPages; x++) {
                numOfPaginationButton++;
                paging.push(
                    <PaginationItem key={x} onClick={event => {
                        pageNumberHandle(event, x)
                    }} active={x === page}>
                        <PaginationLink className="paggination-custom-style" tag="button">
                            {x + 1}
                        </PaginationLink>
                    </PaginationItem>
                );
            }
        }


        const pageNumberHandle = (e: any, data: any) => {
            setCurrentPage(data)
        }

        const previousPageHandle = (e: any) => {
            if (currentPage - 1 >= 0) {
                setCurrentPage(currentPage - 1)
            }
        }

        const nextPageHandle = (e: any) => {
            if (currentPage + 1 < totalPages) {
                setCurrentPage(currentPage + 1)
            }
        }

        const firstPageHandle = (e: any) => {
            if (currentPage - 1 >= 0) {
                setCurrentPage(0)
            }
        }

        const lastPageHandle = (e: any) => {
            if (currentPage + 1 < totalPages) {
                setCurrentPage(totalPages - 1)
            }
        }

        return (
            <Row>
                <Col sm={9} xs={12} lg={9}>
                    <Row>
                        <Col sm={1} />
                    </Row>
                    <Pagination>
                        <PaginationItem key="first" role="firstPageR" onClick={event => { firstPageHandle(event) }} disabled={firstPage} >
                            <PaginationLink className="paggination-custom-style" first tag="button" />
                        </PaginationItem>
                        <PaginationItem key="previous" role="previousPageR" onClick={event => { previousPageHandle(event) }} disabled={firstPage} >
                            <PaginationLink className="paggination-custom-style" previous tag="button" />
                        </PaginationItem>
                        {paging}
                        <PaginationItem key="next" role="nextPageR" onClick={event => { nextPageHandle(event) }} disabled={lastPage}>
                            <PaginationLink className="paggination-custom-style" next tag="button" />
                        </PaginationItem>
                        <PaginationItem key="last" role="lastPageR" onClick={event => { lastPageHandle(event) }} disabled={lastPage}>
                            <PaginationLink className="paggination-custom-style" last tag="button" />
                        </PaginationItem>
                    </Pagination>
                </Col>
                <Col style={{ color: '#1FA8D8', textAlign: "right" }} sm={3}>
                    Total Record : {totalElements}
                </Col>
            </Row>
        )
    }

    const renderTable = () => {
        if (permissionData?.length > 0) return permissionData?.map((data: any, idx) => {
            let mapData = {
                code: data?.code,
                description: data?.description,
                flagActive: data?.flagActive,
                name: data?.name,
                pmdId: Number(data?.pmdId),
                flagPlanCode: data?.variable1,
                variable2: data?.variable2,
            }
            return (
                <tr key={data?.pmdId} style={{ verticalAlign: "middle" }}>
                    {data.flagActive === 'Y' ? <td style={{ ...fontActive, textAlign: "center" }}>ACTIVE</td> : <td style={{ ...fontInActive, textAlign: "center" }}>INACTIVE</td>}
                    <td style={{ textAlign: "center" }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                '& svg': {
                                    m: 1.5,
                                },
                                '& hr': {
                                    mx: 0.5,
                                },
                            }}
                        >
                            <MuiButton variant="contained" color="warning" size="small" onClick={() => handleEdit(mapData)}>EDIT</MuiButton>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <MuiButton variant="contained" color="error" size="small" onClick={() => deleteParameter(mapData.pmdId)}>DELETE</MuiButton>
                        </Box>

                    </td>
                    <td style={{ textAlign: "center" }}> {data.code}</td>
                    <td style={{ textAlign: "start" }}>
                        {data.description}
                    </td>
                </tr>
            )
        })
        else return null
    }
    const [parameterDetailPage, setParameterDetailPage] = useState<boolean>(false)
    const [pmdData, setPmdData] = useState<any>(null)

    const handleEdit = (mapData: any) => {
        setParameterDetailPage(true)
        setPmdData(mapData)
    }

    const handleAdd = (mapData: any) => {
        setParameterDetailPage(true)
        setPmdData(null)
    }

    useEffect(() => {
        if ((!!code && (code != codeRef.current))) {
            codeRef.current = code
            setParameterDetailPage(false)
            setCurrentPage(0)
            fetchData(0)
        }
        if (window.performance) {
            if (performance.navigation.type == 1) {
                setParameterDetailPage(false)
                fetchData(currentPage)
            }
        }
    }, [code])

    useEffect(() => {
        if (!parameterDetailPage) {
            fetchData(currentPage)
        }
    }, [currentPage, parameterDetailPage])


    const deleteParameter = async (pmdId: number) => {

        const res: any = await ConfigurationAPI.deleteParameterDetail(pmdId)
        if (res.status === 202) {
            let variant: VariantType = "success"
            enqueueSnackbar(`Delete Success !`, { variant });
            fetchData(currentPage)
        } else {
            let variant: VariantType = "warning"
            enqueueSnackbar(`Delete Fail !!`, { variant });
        }
    }

    return parameterDetailPage ? <ParameterDetail pmtId={pmtId} pmdData={pmdData} setParameterDetailPage={setParameterDetailPage} refreshPage={fetchData} currentPage={currentPage} /> : (
        <div className="animated fadeIn">
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Header title={TitleData[`${location?.pathname.split("/admin/config/")[1]}`]?.title} subtitle={TitleData[`${location?.pathname.split("/admin/config/")[1]}`]?.subtitle} >
                                    <MuiButton variant="contained" color="success" onClick={handleAdd}>Add</MuiButton>
                                </Header>
                            </Row>
                            <div className="container-fluid">
                                <div>
                                    <Table hover responsive className="table-outline d-sm-table">
                                        <thead className="table-th-background">
                                            <tr style={{ verticalAlign: "middle" }}>
                                                <th style={{ textAlign: "center", width: '10%' }}>STATUS</th>
                                                <th style={{ textAlign: "center", width: '20%' }}>ACTION</th>
                                                <th style={{ textAlign: "center", width: '15%' }}>CODE</th>
                                                <th style={{ textAlign: "center", width: '55%' }}>DESCRIPTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderTable()}
                                        </tbody>
                                    </Table>
                                    {renderPagination()}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Configuration