import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthContextProvider from './Utils/AuthContextProvider';
import ReceiptContextProvider from './Utils/ReceiptContextProvider';
import ReceiptNextContextProvider from './Utils/ReceiptNextContextProvider';
import ReceiptReportContextProvider from './Utils/ReceiptReportContextProvider';
import { loadConfig } from './Utils/AppConfig';

loadConfig().then(() => { });

ReactDOM.render(
  // <React.StrictMode>
  <ReceiptReportContextProvider>
    <ReceiptNextContextProvider>
      <ReceiptContextProvider>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </ReceiptContextProvider>
    </ReceiptNextContextProvider>
  </ReceiptReportContextProvider>
  // </React.StrictMode>

  ,
  document.getElementById("root")
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
